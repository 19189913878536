import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import usernameSlice from './usernameSlice';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'username',
  storage,
}

const reducers = combineReducers({ username: usernameSlice });

const persistedReducer = persistReducer(persistConfig, reducers)


export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});

export const persistor = persistStore(store);

