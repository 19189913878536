import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { getUsername } from '../../redux/usernameSlice';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import btcLogo from '../../assets/coinLogos/BTC.png';
import ethLogo from '../../assets/coinLogos/ETH.png';
import './WalletTile.css';
import 'bootstrap/dist/css/bootstrap.min.css';

// wallets and their info passed as props
interface IWallet {
  username: string;
  public_address: string;
  currency_name: string;
  wallet_name: string;
}

interface WalletTileProps {
  wallet: IWallet;
}

const WalletTile :  React.FC<WalletTileProps> = (props) => {
  const [balanceFiat, setBalanceFiat] = useState(0);
  const [balanceCrypto, setBalanceCrypto] = useState(0);

  const usernameSlice = useSelector(getUsername);
  const username = usernameSlice.payload.username;

  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  let backend = process.env.REACT_APP_BACKEND_URL;
  if (process.env.REACT_APP_DEV === 'TRUE') {
    backend = process.env.REACT_APP_LOCAL_BACKEND_URL;
  }

  useEffect(() => {
    if(accessToken && username.length > 0) {
    // TODO: Pass balance through props in WalletList.tsx (aslo add "balance" to IWallet)
    axios.get(backend+`/api/wallets/${props.wallet.wallet_name}`, { headers })
      .then((response) => {
        const wallet: IWallet = response.data.wallet;
        const balance = response.data.balance;
        axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=BTC,USD,EUR')
        .then(response => {
          const price_ETH = response.data.USD;
          setBalanceFiat(Number((price_ETH * balance).toFixed(2)));
        })
        .catch(error => {
          console.log('Error fetching fiat balance: ' + error);
        });
        setBalanceCrypto(Number((1 * balance).toFixed(4)));
      })
      .catch((error) => {
        console.error('Error retrieving wallet: ', error);
      });
    }
  }, []);

  return (
    <Link to={`/wallets/${props.wallet.wallet_name}`} className="wallet-tile rounded">
        <h2 className="wallet_name">{props.wallet.wallet_name}</h2>
        <div className='d-flex justify-content-center'>
          <div className="left-box">
            <Row className="text-boxes">
              <Col xs={12} sm={6}>
                <div className="text-box">
                  <h5 className="text-success fs-3">&#128176;  {balanceFiat}</h5>
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <div className="text-box">
                  <p className="text-dark fs-3">{props.wallet.currency_name} {balanceCrypto}</p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="logo-container">
            <img
              src={props.wallet.currency_name === 'BTC' ? btcLogo : ethLogo}
              alt={props.wallet.currency_name === 'BTC' ? 'Bitcoin Logo' : 'Ethereum Logo'}
              className="currency-logo"
            />
          </div>
        </div>
    </Link>
  );
}

export default WalletTile;