import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateUsername } from '../../redux/usernameSlice';
import './Signup.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Particle from '../Particle/Particle';

function Signup() {
    const [credentials, setCredentials] = useState({
        email: '',
        username: '',
        password: '',
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setCredentials({
          ...credentials,
          [name]: value,
        });
    };
    let backend = process.env.REACT_APP_BACKEND_URL;
    if (process.env.REACT_APP_DEV === 'TRUE') {
      backend = process.env.REACT_APP_LOCAL_BACKEND_URL;
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    
        try {
          // Send a POST request to the backend for authentication
          const ctreateUserResponse = await axios.post(backend+'/api/user/create', credentials);
          const response = await axios.post(backend+'/api/login', credentials);
          const { message, accessToken, refreshToken } = response.data;
    
          // Store the JWT token in local storage or state (handle securely)
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);

          if (accessToken) {
            console.log('dispatching username change redux')
            dispatch(updateUsername(credentials.username));
            navigate('/home');
          }
    
          console.log('Authentication response:', message);
        } catch (error) {
          console.error('Error authenticating:', error);
        }
      };

    return (
        <div>
            <Particle/>
            <div className="signup template d-flex justify-content-center align-items-center w-100 vh-100">
                <div className='form_container p-5 rounded bg-white'>
                    <form onSubmit={handleSubmit} >
                        <h3>Sign Up</h3>
                        <div className="mb-0">
                            <label htmlFor="username"></label>
                            <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder='Username'
                            value={credentials.username}
                            onChange={handleInputChange}
                            className="form-control"
                            />
                        </div>
                        <div className="mb-0">
                            <label htmlFor="email"></label>
                            <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder='Email'
                            value={credentials.email}
                            onChange={handleInputChange}
                            className="form-control"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password"></label>
                            <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder='Password'
                            value={credentials.password}
                            onChange={handleInputChange}
                            className="form-control"
                            />
                        </div>
                        <div className='mb-2'>
                            <input 
                            type='checkbox' 
                            className='custom-control custom-checkbox'
                            id='check'
                            />
                            <label htmlFor='check' className='custom-input-label ms-2'>
                            Remember me
                            </label>
                        </div>
                        <div className='d-grid'>
                            <button className="btn btn-primary"> Sign up</button>
                        </div>
                        <p className='text-end mt-2'>
                            Already Registered<Link to='/login' className='ms-2 '>Sign in</Link>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default Signup;
