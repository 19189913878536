import React, { useCallback } from 'react';
import Particle from '../Particle/Particle';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './Landing.css';


const Landing = () => {
    return (
        <div>
            <div className='landing-container'> 
                <Particle/>
                <h1 style={{color:'navy' }}>Your Gateway to Crypto Freedom</h1>
                <h3 style={{ color: '#2D64F5' }}>Buy, Receive, and Sell Cryptocurrency with Ease</h3>
                <div className='hero'>
                    <div>
                    <Link to="/login">
                        <Button className="gradient-button">Login</Button>
                    </Link>
                    <Link to="/signup">
                        <Button className="gradient-button">Sign Up</Button>
                    </Link>
                    </div>
                </div>
            </div>
            <div className='landing-container'> 
            <h1 style={{color:'navy' }}>Why Use a Wallet?</h1>
            <div className='d-flex'>
                <h3 style={{ color: '#2D64F5', marginRight: '40px' }}>Online Shopping</h3>
                <h3 style={{ color: '#2D64F5', marginRight: '40px' }}>Investing</h3>
                <h3 style={{ color: '#2D64F5' }}>Transfer of Funds</h3>
            </div>
            </div>
        </div>
    );
};

export default Landing;