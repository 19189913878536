import axios from 'axios';
import React, { ReactNode, useState } from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  children?: ReactNode;
}

const ProtectedRoute = ({ children }: Props) => {
  const [authenticated, setAuthenticated] = useState<boolean | null>(null);

  const authenticate = async () => {
    let backend = process.env.REACT_APP_BACKEND_URL;
    if (process.env.REACT_APP_DEV === 'TRUE') {
      backend = process.env.REACT_APP_LOCAL_BACKEND_URL;
    }
    const accessToken = localStorage.getItem('accessToken'); 
    const refreshToken = localStorage.getItem('refreshToken');
    const payload = {
      accessToken: accessToken,
      refreshToken: refreshToken,
    }

    try {
      const response = await axios.post(backend + '/api/validate-tokens', payload);
      if (response.status === 200) {
        console.log('Tokens successfully validated.');
        const { newAccessToken, newRefreshToken } = response.data;
        if (typeof newAccessToken !== 'undefined' && newAccessToken !== null && accessToken !== newAccessToken) { // access token was refreshed
          localStorage.setItem('accessToken', newAccessToken);
        }
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    } catch (error) {
      console.error('Error validating tokens: ', error);
      setAuthenticated(false);
    }
  };

  // Call authenticate and wait for response before rendering
  authenticate();

  if (authenticated === true) {
    return <>{children}</>; // Render children if authenticated
  } else if (authenticated === false) {
    return <Navigate to="/login" />; // Navigate to login on false credentials
  }

  return <div>Trying to authenticate user... please refresh page if not redirected</div>; // Render a loading indicator while waiting for response
};

export default ProtectedRoute;
