import React, { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUsername } from '../../redux/usernameSlice';
import 'bootstrap/dist/css/bootstrap.min.css';

function CreateWallet() {
  const [walletType, setWalletType] = useState('ETH');
  const [walletName, setWalletName] = useState('');

  const usernameSlice = useSelector(getUsername);
  const username = usernameSlice.payload.username;

  const navigate = useNavigate();

  const accessToken = localStorage.getItem('accessToken');
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log("trying to create a new wallet")
    const data = {
      username,
      currencyName: walletType,
      walletName: walletName,
    };

    let backend = process.env.REACT_APP_BACKEND_URL;
    if (process.env.REACT_APP_DEV === 'TRUE') {
      backend = process.env.REACT_APP_LOCAL_BACKEND_URL;
    }

    await axios.post(backend+'/api/wallet/create', data,{ headers })
    .then(response => {
      let message = response.data.message;
      console.log("New wallet request returned: ", message);
      navigate('/home');
    })
    .catch(error => {
    console.error('Axios error:', error);
    });

  };

  return (
    <div className='signup template d-flex justify-content-center align-items-center w-100 vh-100 bg-primary'>
      <div className='form_container p-5 rounded bg-white'>
        <h3>Create New Wallet</h3>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Wallet Type:</label>
            <select
              value={walletType}
              onChange={(e) => setWalletType(e.target.value)}
            >
              {/* <option value="BTC">Bitcoin</option> */}
              <option value="ETH">Ethereum</option>
            </select>
          </div>
          <div className="mb-2">
            <label htmlFor="walletname">Wallet name:</label>
            <input
            type="text"
            id="walletname"
            name="walletname"
            placeholder='Enter wallet name'
            value={walletName}
            onChange={(e) => setWalletName(e.target.value)}
            className="form-control"
            />
          </div>
          <div>
            <button type="submit" className='btn btn-success'>Create Wallet</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateWallet;
