import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeUsername } from '../../redux/usernameSlice';
import './Header.css'; // Import your CSS file
import FortiCoinLogo from '../../assets/coinLogos/FortiCoin.png';

function Header() {
    const dispatch = useDispatch();

    const handleLogout = () => {
      // Remove token from localStorage (if needed)
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
  
      // Clear the username in Redux
      dispatch(removeUsername());
    };

    return (
      <div className='header'>
        <div className='left-section'>
          <Link to='/home'>
            <img src={FortiCoinLogo} alt='FortiCoin Logo' className='logo' width='50' height='50' />
          </Link>
        </div>
        <div className='right-section'>
          <span className='hover-text'>
            <Link to='/contact'>Feedback</Link>
          </span>
          <span className='hover-text'>
            <Link to='/donate'>Donate &#10084;</Link>
          </span>
          <span className='hover-text'>
            <Link to='/login' onClick={handleLogout}>Sign out</Link>
          </span>
        </div>
      </div>
    );
  }
export default Header;
