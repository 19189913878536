import React, { useState, useEffect} from 'react';
import axios from 'axios';
import { ethers } from "ethers";
import Button from 'react-bootstrap/Button';
import { Col, Row, Form, Popover, OverlayTrigger } from 'react-bootstrap';
import btcLogo from '../../assets/coinLogos/BTC.png';
import ethLogo from '../../assets/coinLogos/ETH.png';
import './Wallet.css';
import { useNavigate, useParams } from 'react-router-dom';
import Particle from '../Particle/Particle';
import 'bootstrap/dist/css/bootstrap.min.css';

interface IWalletPartial {
  username: string;
  public_address: string;
  currency_name: string;
  wallet_name: string;
}

const ColoredBox = ({ color, content }: { color: string, content: JSX.Element }) => {
  const boxStyle = {
    backgroundColor: color,
    borderRadius: '10px',
    
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  };

  return <div className='coloredBox'style={boxStyle}>{content}</div>;
};

function Wallet() {
  const { wallet_name } = useParams();
  // Source: https://ethereum.stackexchange.com/questions/38309/what-are-the-popular-api-to-get-current-exchange-rates-for-ethereum-to-usd
  const [balance, setBalance] = useState(0);
  const [balanceFiat, setBalanceFiat] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showReceiveInfo, setShowReceiveInfo] = useState(false);
  const [addressTo, setAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionStatus, setTransactionStatus] = useState('not started.');
  const [isCheckedFiat, setIsCheckedFiat] = useState(true);
  const [qrCodeURL, setQRCodeURL] = useState('');
  const [wallet, setWallet] = useState<IWalletPartial>({
    username: '',
    public_address: '',
    currency_name: '',
    wallet_name: '',
  });
  const navigate = useNavigate();

  const accessToken = localStorage.getItem('accessToken');
  const handleToggle = () => setIsCheckedFiat(!isCheckedFiat);

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  let backend = process.env.REACT_APP_BACKEND_URL;
  if (process.env.REACT_APP_DEV === 'TRUE') {
    backend = process.env.REACT_APP_LOCAL_BACKEND_URL;
  }

  const revealReceiveInfo = async (e: any) => {
    if (!showReceiveInfo) {
      setShowReceiveInfo(!showReceiveInfo);
    }
  }

  const sendTransaction = async (e: any) => {
    if (!showForm) {
      setShowForm(!showForm);
    } else {
      try {
        let value = BigInt(0);
        let temp = Number(amount);
        if (!isCheckedFiat) { // convert ETH-> Wei
          value = BigInt(Math.ceil((temp * Math.pow(10, 18)))); // 18 is for Ether
        }
        setTransactionStatus('processing transaction...');
        e.preventDefault();

        if (isCheckedFiat) { //convert USD -> ETH -> Wei
          await axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=BTC,USD,EUR')
            .then(response => {
              const price_ETH = response.data.USD;
              temp /= price_ETH;
              console.log("eth1: " + temp)
              const valueStr = temp.toString();
              console.log("eth2: " + valueStr)
              value = BigInt(Math.ceil((temp * Math.pow(10, 18)))); // 18 is for Ether
              console.log("wei: " + value)
            })
            .catch(error => {
              setTransactionStatus('transaction faied.');
              console.log('Error fetching fiat balance: ' + error);
            });
        }

        console.log("sending: " + value)
        const sendData = {
          value: value.toString(),
          address_to: addressTo,
        };
  
        axios.post(
          backend+'/api/wallets/'+wallet_name+'/send',
          sendData,
          { headers }
        )
        .then(response => {
          if (response.status < 400) {
            console.log('transaction sent successfully, hash:');
            setTransactionStatus('successfully sent transaction');
          } else {
            setTransactionStatus('fail');
          }
        })
        .catch(error => {
          setTransactionStatus('transaction failed, ' + error.response.data.error);
        });
      } catch (error: any) {
        setTransactionStatus('Uncaught error: ' + error);
        console.error('Uncaught error: ' + error);
      }
    }
  };

  useEffect(() => {
    if(accessToken) {
        axios.get(backend+`/api/wallets/${wallet_name}`, { headers })
        .then((response) => {
          const wallet: IWalletPartial = response.data.wallet;
          setQRCodeURL(`https://chart.googleapis.com/chart?chs=150x150&cht=qr&chl=${wallet.public_address}&choe=UTF-8`);
          // ADD chs=300x300&cht=qr&chl={REPLACE WITH ETH ADDRESS}&choe=UTF-8
          const balance = response.data.balance;
          axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=BTC,USD,EUR')
          .then(response => {
            const price_ETH = response.data.USD;
            setBalanceFiat(Number((price_ETH * balance).toFixed(2)));
          })
          .catch(error => {
            console.log('Error fetching fiat balance: ' + error);
          });
          setWallet(wallet);
          setBalance(Number((1 * balance).toFixed(4)));
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error retrieving wallet: ', error);
          setLoading(false);
        });
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  
  const walletTile = 
    <div className='wallet-container'>
      <h2 className="wallet_name">{wallet.wallet_name}</h2>
      <div className='d-flex justify-content-center'>
        <div className="left-box">
          <Row className="text-boxes">
            <Col xs={12} sm={3}className="text-box">
              <div>
                <h5 className="text-success fs-3">&#128176;  {balanceFiat}</h5>
              </div>
            </Col>
            <Col xs={12} sm={3}className="text-box">
              <div>
                <p className="text-dark fs-3">{wallet.currency_name} {balance}</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="logo-container">
          <img
            src={wallet.currency_name === 'BTC' ? btcLogo : ethLogo}
            alt={wallet.currency_name === 'BTC' ? 'Bitcoin Logo' : 'Ethereum Logo'}
            className="currency-logo"
          />
        </div>

      </div>
    </div>

const sendBoxContent = (
  <div  className="d-flex justify-content-center align-items-center vh-100">
    {showForm && (
      <div className='bg-white p-4 rounded' style={{ maxWidth: '100%', maxHeight: '75%' }}>
        <div className='sendBox container text-center p-0'>
          <form>
            <Form>
              <div className="d-flex align-items-center justify-content-center mb-0">
                <Form.Check
                  type="switch"
                  id="toggle-switch"
                  label={isCheckedFiat ? <p>Send in &#128176;</p> : <p>Send in {wallet.currency_name}</p>} 
                  checked={isCheckedFiat}
                  onChange={handleToggle}
                />
              </div>
            </Form>
            
            <div className='mb-0'>
              <div className='d-flex justify-content-between align-items-center'>
                <label>Amount, {isCheckedFiat ? '$' : 'ETH'}:</label>
                <input
                  type="number"
                  id="amount"
                  placeholder='10'
                  value={amount}
                  onChange={(e) => {setAmount(e.target.value); setTransactionStatus("in progress.")}}
                  required
                />
              </div>
            </div>
            <div className='mb-0'>
              <div className='d-flex justify-content-between align-items-center'>
                <label>Address to:</label>
                <input
                  type="text"
                  id="address"
                  placeholder='0x1234567890'
                  value={addressTo}
                  onChange={(e) => {setAddress(e.target.value); setTransactionStatus("in progress.")}}
                  required
                />
              </div>
            </div>
            <div className='mb-0'>
              <div className='d-flex justify-content-between align-items-center'>
                <p id='transactionStatusText'>Status: {transactionStatus}</p>
              </div>
            </div>
          </form>
          <Button className='btn btn-primary pb-1 bt-1'  onClick={sendTransaction}>Send</Button> 
        </div> 
      </div>
    )}
    {!showForm &&<Button variant="primary" onClick={sendTransaction}><p>Send ETH</p></Button>} 
  </div>
);


  const buyButton =
  (
    <Popover id="popover-positioned-bottom" className='p-1' title="Popover bottom">
      This feature is available to VIP users only
    </Popover>
  );

  const src = "https://widget.changelly.com?from=usd%2Crub&to=eth&amount=150&address=" + wallet.public_address +  "&fromDefault=usd&toDefault=eth&merchant_id=LTDNmKxb7gouwlOT&payment_id=&v=3"

  const changellyWidget =
  <iframe width="100%" height="100%" allow="camera" src={src}>Can't load widget</iframe>

  const buyPopover =
    <OverlayTrigger trigger="click" placement="bottom" overlay={buyButton}>
      <Button className='btn btn-success'>Buy {wallet.currency_name}</Button>
    </OverlayTrigger>

  const receiveBoxContent =
  <div>
  {showReceiveInfo && (
  <div className='bg-white p-4 rounded mt-3' style={{ width: '100%', height: '75%' }}>
    <div className='receiveBox container vh-250 d-flex justify-content-center align-items-center'>
      <div className='text-center d-flex flex-column align-items-center'>
        <h3>Receive {wallet.currency_name}</h3>
        <img src={qrCodeURL} alt="QR Code" />
        <h3 className='publicAddressText'>{wallet.public_address}</h3>
      </div>
    </div>
  </div>)}
  {!showReceiveInfo && (
    <Button variant="primary" onClick={revealReceiveInfo}>{<p>Receive {wallet.currency_name}</p>}</Button> 
  )}
</div>

  return (
      <div className="d-flex justify-content-center align-items-center vh-200">
        <Particle/>
        <div className='coloredBoxContainer'>
          <div className="row">
            <div className="col">
              <ColoredBox color="rgb(47,141,255)" content={walletTile} />
            </div>
            <div className="col">
              <ColoredBox color="rgb(50, 138, 216)" content={changellyWidget} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <ColoredBox color="rgb(128, 182, 71)" content={sendBoxContent}/>
            </div>
            <div className="col">
              <ColoredBox color="rgb(106, 90, 205)" content={receiveBoxContent}/>
            </div>
          </div>
        </div>
      </div>
  )
};

export default Wallet;