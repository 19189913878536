import React from 'react';
import './Donate.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Particle from '../Particle/Particle';

function Donate() {
    return (
        <div className='container-fluid min-vh-100 d-flex justify-content-center'>
            <Particle/>
            <div className='bg-white p-4 rounded mt-5 text-wrap' style={{ width: '75%', height: '100%'}}>
                <h1>Donate &#10084;</h1>
                <br></br>
                <h3>
                Welcome to our Donate page! We're thrilled to have you here and grateful for your interest in supporting FortiCoin.
                <br></br>
                <br></br> 
                Your generosity plays a crucial role in enabling us to continue providing a secure and seamless crypto experience for all our users.
                <br></br>
                <br></br>
                Every contribution, regardless of size, brings us closer to achieving our goals. Rest assured that your support will be acknowledged and cherished by our dedicated team.
                <br></br>
                <br></br>
                Thank you for being a part of our journey. Together, we're making FortiCoin wallet even better!
                <br></br>
                <br></br>
                Warm regards,
                FortiCoin Team.
                </h3>
                <br></br>
                <h1>Addresses: </h1>
                <h3>Ethereum </h3>
                <h3 className='addressText'>0x3cF002e3d719302ff1C0EEC616995d96c1A668Be</h3>
            </div>
        </div>
    );
}

export default Donate;