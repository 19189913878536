// WalletsList.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getUsername } from '../../redux/usernameSlice';
import { Link } from 'react-router-dom';
import WalletTile from '../WalletTile/WalletTile';
import 'bootstrap/dist/css/bootstrap.min.css';


interface IWalletPartial {
  username: string;
  public_address: string;
  currency_name: string;
  wallet_name: string;
}

function WalletList(this: any) {
  
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(true);
  const usernameSlice = useSelector(getUsername);
  const username = usernameSlice.payload.username;

  const accessToken = localStorage.getItem('accessToken');

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };

  let backend = process.env.REACT_APP_BACKEND_URL;
  if (process.env.REACT_APP_DEV === 'TRUE') {
    backend = process.env.REACT_APP_LOCAL_BACKEND_URL;
  }

  useEffect(() => {
    if(accessToken) {
        axios.get(backend+'/api/wallets', { headers })
        .then((response) => {
          setWallets(response.data); // TODO: validate that response contains list of wallets
          setLoading(false);
        })
        .catch((error) => {
          console.error('Axios error:', error);
          setLoading(false);
        });
    } else {
      console.error("No access token present.")
    }
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  


  return (
    <div className='d-flex justify-content-center'>
      <div className="row row-cols-1 row-cols-md-2">
        {wallets.map((wallet: IWalletPartial) => (
          <div className="col mb-1 d-flex justify-content-center">
            <WalletTile wallet={wallet}/>
          </div>
        ))}
      </div>
    </div>
  );
}

export default WalletList;
