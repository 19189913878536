import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import WalletList from '../WalletList/WalletList';
import Particle from '../Particle/Particle';
import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {
    return (
        <div>
            <Particle/>
            <WalletList/>
            <Link to="/create-wallet">
                <div className='h-100 d-flex align-items-center justify-content-center'>
                <Button variant="primary" className='mb-4 btn text-light bg-success ml-1'>Create Wallet</Button>
                </div>
            </Link>
        </div>
    );
}

export default Home;
