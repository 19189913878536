import { createSlice } from '@reduxjs/toolkit';

const yourSlice = createSlice({
  name: 'username', // Name for your slice
  initialState: '', // '' is no username
  reducers: {
    updateUsername: (state, action) => {
      return action.payload;
    },
    getUsername: (state, action) => {
      return state
    },
    removeUsername: () => {
      return ''
    }
  },
});

export const { updateUsername, getUsername, removeUsername } = yourSlice.actions;
export default yourSlice.reducer;
