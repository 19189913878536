import React, { useState } from 'react';
import jwt_decode from "jwt-decode";
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Contact.css'; // Import custom CSS (if needed)
import Particle from '../Particle/Particle';

function Contact() {
  const [feedback, setFeedback] = useState('');
  const USERNAME_DEFAULT = 'NULL';

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const accessToken = localStorage.getItem('accessToken');
    let username = null;

    if (accessToken) {
      const decodedToken: any = jwt_decode(accessToken);
      username = decodedToken.username;
    }

    if (username === null) {
        username = USERNAME_DEFAULT;
    }

    let backend = process.env.REACT_APP_BACKEND_URL;
    if (process.env.REACT_APP_DEV === 'TRUE') {
      backend = process.env.REACT_APP_LOCAL_BACKEND_URL;
    }

    axios.post(backend + '/api/feedback', { feedback, username })
    .then(response => {
      console.log('Feedback sent successfully:', response.data);
    })
    .catch(error => {
      console.error('Error sending feedback:', error);
    });
    setFeedback('');
  };

  return (
    <div className="container-fluid min-vh-100 d-flex justify-content-center">
      <Particle/>
      <div className='bg-white p-4 rounded mt-5' style={{ width: '75%', height: '100%' }}>
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <h1 className="text-center">Comments, Questions, Concerns?</h1>
              </div>
              <div className="mb-3">
                <textarea
                  className="form-control"
                  style={{  height: '300px' }}
                  id="feedbackTextarea"
                  rows={8}
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  required
                ></textarea>
              </div>
              <div className="text-center">
                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Submit Feedback</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;