import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Login, Home, Header, CreateWallet, WalletList, Wallet, Signup, Donate, Contact, Landing} from './components';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import {store} from './redux/store';
import ProtectedRoute from './ProtectedRoute';


export default function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path="/donate" element={<Donate/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path='/' element={<Landing/>}></Route>
        <Route path='/home' element={<ProtectedRoute><Home/></ProtectedRoute>}></Route>
        <Route path='/create-wallet' element={<ProtectedRoute><CreateWallet/></ProtectedRoute>}></Route>
        <Route path="/wallets/:wallet_name" element={<ProtectedRoute><Wallet/></ProtectedRoute>}></Route>
      </Routes>
    </BrowserRouter>
  );
}


